.scrollbarY {
	padding: 0 0 0 0;
	overflow-y: auto;
	overflow-x: visible;
	mask-image: linear-gradient(to top, transparent, black),
		linear-gradient(to left, transparent 8px, black 8px);
	mask-size: 100% 20000px;
	mask-position: left bottom;
	-webkit-mask-image: linear-gradient(to top, transparent, black),
		linear-gradient(to left, transparent 8px, black 8px);
	-webkit-mask-size: 100% 20000px;
	-webkit-mask-position: left bottom;
	transition: mask-position 0.3s, -webkit-mask-position 0.3s;
}
.scrollbarY:hover {
	-webkit-mask-position: left top;
	
}
.scrollbarY::-webkit-scrollbar
{
	width: 8px;
}
.scrollbarY::-webkit-scrollbar-thumb
{
	border-radius: 16px;
	background-color: #8d8e90;
	-webkit-box-shadow: inset 0 0 8px rgba(0,0,0,.3);
}

.scrollbarX {
	padding: 0 0 0 0;
	overflow-x: auto;
	mask-image: linear-gradient(to right, transparent, black),
		linear-gradient(to top, transparent 8px, black 8px);
	mask-size: 20000px 100%;
	mask-position: left bottom;
	-webkit-mask-size: 20000px 100%;
	-webkit-mask-position: left bottom;
	-webkit-mask-image: linear-gradient(to right, transparent, black),
		linear-gradient(to top, transparent 8px, black 8px);
	transition: mask-position 0.3s, -webkit-mask-position 0.3s;
}
.scrollbarX:hover {
	-webkit-mask-position: right bottom;
}
.scrollbarX::-webkit-scrollbar
{
	height: 8px;
}
.scrollbarX::-webkit-scrollbar-thumb
{
	border-radius: 16px;
	-webkit-box-shadow: inset 0 0 8px rgba(0,0,0,.3);
	background-color: #8d8e90;
}